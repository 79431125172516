
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { Table } from "@/utils/decorator";
import { getImageSize, emptyToLine } from "@/utils/common";
import CouponDiscountContent from "../components/coupon-discount-content.vue";
import dayjs from "dayjs";
import { verifyMoney, isPositiveIntegerZero } from "@/utils/verify";
import t from "@common/src/i18n/t";

const app = namespace("app");
const store = namespace("store");
const campaign = namespace("campaign");
const coupon = namespace("coupon");
const fission = namespace("fission");
const base = namespace("base");
const marketing = namespace("marketing");
const defaultBg = "rgb(236,246,245)";
const defaultBannerUrl =
  "https://speedbiz-public.oss-cn-beijing.aliyuncs.com/static/speedbiz/minipro/accumulate-banner.jpg";
const defaultCampaignThumbnail =
  "https://speedbiz-public.oss-cn-beijing.aliyuncs.com/static/speedbiz/minipro/accumulate-small.jpg";
@Component({
  components: { FileUpload, Editor, CouponDiscountContent }
})
@Table("loadListData", { pageSize: 5 })
export default class CollectCustomerEdit extends Vue {
  @store.State storeData;
  @campaign.Action createSaveCampaign;
  @campaign.Action queryCampaignDetail;
  @base.Action getDictionaryList;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @coupon.Action queryList;
  @app.Mutation setPBPX;
  @marketing.Action queryCardTemplateList;
  @marketing.Action updateFission;
  @marketing.Action insertFission;
  // @fission.Action insertFission;
  // @fission.Action updateFission;
  @marketing.Action queryFissionCampaign;
  emptyToLine = emptyToLine;
  campaignForm: any = {
    id: "defalut", // 上传图片用的
    campaignName: "",
    campaignBeginTime: "",
    campaignEndTime: "",
    campaignImage: "",
    campaignThumbnail: "", // 活动缩略图
    campaignDesc: "",
    isCoupon: "1",
    campaignContext: "",
    campaignImageColor: "",
    fissionPrize: {
      bindCode: "",
      prizeAmount: "",
      prizeQty: ""
    },
    // 非业务直接参数
    time: null,
    bannerType: "customize" // default customize
  };
  dayjs = dayjs;
  showCouponSelector: boolean = false;
  get campaignFormRules() {
    return {
      campaignName: [
        { required: true, message: t("v210801.please-enter-the-campaign-title"), trigger: "blur" },
        { max: 14, message: t("v210801.the-maximum-length"), trigger: "blur" }
      ],
      time: { type: "array", required: true, message: t("v210801.please-select-an"), trigger: "change" },
      campaignImage: { required: true, validator: this.checkFrontImage },
      campaignThumbnail: { required: true, validator: this.checkFrontImage },
      campaignContext: [{ required: true, message: t("v210801.please-enter-the"), trigger: "blur" }]
    };
  }
  checkFrontImage(rule, value, callback) {
    if (!value) {
      callback(new Error(t("v210801.please-upload-pictures") as string));
    } else {
      callback();
    }
  }
  handleBannerChange(val) {
    if (val === "default") {
      this.campaignForm.campaignImageColor = defaultBg;
      this.campaignForm.campaignImage = defaultBannerUrl;
      this.defaultBannerList = [];
    } else {
      this.$nextTick(() => {
        this.campaignForm.campaignImage = "";
      });
    }
  }
  get breadData() {
    return [
      { name: t("v210801.fission-activity"), path: "/marketing/campaign/fission-campagin" },
      {
        name: this.isAdd ? t("v210801.new-fission-activity") : t("v210801.edit-fission-activity")
      }
    ];
  }
  searchForm = {
    cardTemplateName: "",
    enableStatus: 1, //（1：启用、0：禁用）integer 只显示启用的卡
    cardType: 1 //（1：套餐卡、2：储蓄卡）integer 0630这个版本先写死
  };
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  selectedRow: any = {};
  confirmSelecteRow: any = {
    bindCode: undefined,
    prizeAmount: undefined,
    prizeQty: undefined
  };
  handleRowSelect(rows) {
    this.selectedRow = rows.length > 0 ? rows[0] : {};
  }
  checkable(row) {
    return !Object.keys(this.selectedRow).length || this.selectedRow.cardTemplateCode === row.cardTemplateCode;
  }
  handleSelectedCoupon() {
    if (!this.selectedRow) {
      return this.$message.error(t("v210801.please-select-a") as string);
    }
    this.confirmSelecteRow = Object.assign({}, this.selectedRow);
    this.showCouponSelector = false;
  }
  get couponSearchList() {
    return [
      {
        label: t("v210801.card-template-name"),
        type: "input",
        value: "",
        prop: "cardTemplateName"
      }
    ];
  }
  campaignStatusList: any = [];
  get isAdd() {
    return this.$route.name === "fissionCampaginAdd";
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  get id() {
    return this.$route.params.id;
  }
  get copyCode() {
    return this.$route.query.copyCode;
  }
  defaultBannerList: any = [];
  defaultShareList: any = [];
  init() {
    // this.handleBannerChange("default");
    this.getDictionaryList("coupon_activity_status").then(data => {
      this.campaignStatusList = data.filter(item => item.value !== "05" && item.value !== "07");
    });
    if (!this.isAdd || this.copyCode) {
      this.queryFissionCampaign({ campaignCode: this.id || this.copyCode }).then(data => {
        data.data.time = [
          dayjs(data.data.campaignBeginTime).format("YYYYMMDDHHmmss"),
          dayjs(data.data.campaignEndTime).format("YYYYMMDDHHmmss")
        ];
        if (this.copyCode) {
          delete data.data.campaignCode;
        }
        if (data.data.campaignImage === defaultBannerUrl) {
          this.campaignForm.bannerType = "default";
        } else {
          this.campaignForm.bannerType = "customize";
          this.defaultBannerList = [{ url: data.data.campaignImage }];
        }
        this.defaultShareList = [{ url: data.data.campaignThumbnail }];
        this.confirmSelecteRow = data.data.fissionPrize
          ? Object.assign(data.data.fissionPrize.cardTemplateResult, data.data.fissionPrize)
          : {};
        Object.assign(this.campaignForm, data.data);
      });
    }
  }
  mounted() {}
  handleRemoveCoupon() {
    this.selectedRow = {};
    this.confirmSelecteRow = {};
  }
  handleBannerUploadSuccess(url) {
    (this.$refs.campaignForm as any).validateField("campaignImage");
    this.getImageColor(url);
  }
  handleShowCoupon() {
    this.showCouponSelector = true;
    this.loadListData();
  }
  getImageColor(url) {
    console.log(url);
    const _this = this;
    let canvas = document.createElement("canvas"),
      ctxt = canvas.getContext && canvas.getContext("2d");
    var img = new Image();
    // 要求跨域处理
    img.crossOrigin = "Anonymous";
    img.src = url; //src也可以是从文件选择控件中取得。
    img.onload = () => {
      let [width, height] = getImageSize(img);
      canvas.height = height;
      canvas.width = width;
      ctxt.drawImage(img, 0, 0);
      var data = ctxt.getImageData(~~(width / 2), height - 2, 1, 1).data; //读取整张图片的像素。
      let [r, g, b] = data;
      console.log(33333, r, g, b);
      _this.campaignForm.campaignImageColor = `rgb(${[r, g, b].join()})`;
    };
  }
  couponList: any = [];
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryCardTemplateList({
      ...this.searchForm,
      // pageNum: this.mix_pageNum,
      // pageSize: this.mix_pageSize
      pageNum: 1,
      pageSize: 999
    }).then(data => {
      this.couponList = data.data.list;
      return data;
    });
  }
  saveCampaign() {
    (this.$refs.campaignForm as any).validate(valid => {
      console.log(valid);
      console.log("[ this.confirmSelecteRow ]", this.confirmSelecteRow);
      if (valid) {
        this.campaignForm.campaignBeginTime = this.campaignForm.time[0];
        this.campaignForm.campaignEndTime = this.campaignForm.time[1];
        if (this.campaignForm.isCoupon === "1") {
          if (!this.confirmSelecteRow.prizeAmount || !this.confirmSelecteRow.prizeQty) {
            return this.$message.error(t("v210801.please-complete-the") as string);
          }
          if (
            (this.confirmSelecteRow.prizeQty && !isPositiveIntegerZero(this.confirmSelecteRow.prizeQty)) ||
            Number(this.confirmSelecteRow.prizeQty) === 0
          ) {
            return this.$message.error(t("v210801.the-maximum-number") as string);
          }
          if (this.confirmSelecteRow.prizeAmount && !verifyMoney(this.confirmSelecteRow.prizeAmount)) {
            return this.$message.error(t("v210801.the-activity-amount") as string);
          }
          if (!this.confirmSelecteRow.cardTemplateCode) {
            return this.$message.error(t("marketing.please-select-card-template") as string);
          } else {
            this.campaignForm.fissionPrize = {
              bindCode: this.confirmSelecteRow.cardTemplateCode,
              prizeAmount: this.confirmSelecteRow.prizeAmount,
              prizeQty: this.confirmSelecteRow.prizeQty
            };
          }
        }
        if (dayjs(this.campaignForm.time[1]).toDate().getTime() < Date.now()) {
          return this.$message.error(t("v210801.end-time-cannot") as string);
        }
        this.btnStartLoading();
        if (this.isAdd) {
          this.insertFission(this.campaignForm)
            .then(data => {
              this.isAdd
                ? this.$message.success(t("v210801.activity-created-successfully") as string)
                : this.$message.success(t("v210801.activity-edited-successfully") as string);
              this.$router.push("/marketing/campaign/fission-campagin");
            })
            .finally(() => {
              this.btnStopLoading();
            });
        } else {
          this.updateFission(this.campaignForm)
            .then(data => {
              this.isAdd
                ? this.$message.success(t("v210801.activity-created-successfully") as string)
                : this.$message.success(t("v210801.activity-edited-successfully") as string);
              this.$router.push("/marketing/campaign/fission-campagin");
            })
            .finally(() => {
              this.btnStopLoading();
            });
        }
      }
    });
  }
  // ////
}
